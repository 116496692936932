.status-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px; /* Espacio arriba de los botones */
    margin-bottom: -50px; /* Reducir el espacio debajo de los botones */
    gap: 10px; /* Reducir el espacio entre los botones */
  }
  
  .status-button {
    padding: 15px 30px; /* Aumentar el padding para hacer los botones más grandes */
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
    color: white;
    transition: background-color 0.3s;
  }
  
  .status-button.active {
    background-color: #d60a55; /* Color rosado */
  }
  
  .status-button:hover {
    background-color: #d60a55; /* Mismo color rosado para el hover */
  }
  
  .status-button:not(.active) {
    background-color: #0056b3; /* Color azul para los botones no activos */
  }
  
  .status-button:not(.active):hover {
    background-color: #d60a55; /* Mismo color rosado para el hover */
  }
  
  .form-content {
    margin-top: 20px;
  }
  